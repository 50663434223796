.PublicTheme {
  background: #2b247e;
}

.PublicTheme .lander {
  background: #2b247e;
  width:100%;
}

.PublicTheme.Public {
  background: #2b247e!important;
  height: calc(100vh - 2.5rem);
  overflow-y: auto;
  width: 100vw;
  top: 0rem;
  position: absolute;
}

.PublicTheme.Public .lander h1 {
  color: #FFF;
}

.Public .lander .lander-content {
  max-width: 850px;
  line-height: 200%;
}
