.container {
  width: 100vw;
}

.top-right-0-10 {
  margin-top: 6rem;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.route-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.route-wrapper > div {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.form-control-feedback {
  right: 8px;
}

.MenuPage {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0rem;
}

.MenuPage .banner, .FormPage .banner {
  text-transform: uppercase;
  padding-top: 1rem;
  display: block;
  position: absolute;
  pointer-events: none;
  top: 0rem;
  left: 0;
  right: 0;
  bottom: auto;
  height: 4rem;
  width: auto;
  text-align: center;
  z-index: -1;
  white-space: nowrap;
  overflow: hidden;
}

.MenuPage .shelf-nav {
  overflow: hidden;
}

.MenuPage .shelf-nav-label {
  min-width: 0!important;
}

.Form {
  padding-bottom: 10rem;
}

.FormPage .lander {
  font-family: Helvetica, sans-serif;
  margin: 0 0 1rem 0;
  padding: 4rem;
  text-align: center;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 30rem;
  min-width: 30rem;
  box-sizing: border-box;
  padding-top: 8rem;
}

.FormPage .lander .lander-content{
  max-width: none;
  min-width: 75%;
}

.layout .shelf-layout-container {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  height: auto;
  top: 0;
  bottom: 0;
}

.layout .shelf-layout-container .list-holder{
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0;
  transition: all 300ms ease-in-out;
}

.layout .shelf-layout-container .shelf {
  display: block;
  position: absolute;
  left: 0;
  overflow-x: hidden;
  right: auto;
  bottom: 0;
  top: 0;
  transition: all 300ms ease-in-out;
  width: 200px;
}

.ListPage .layout .search-bar {
  position: relative;
  height: 9rem;
  margin-bottom: 1rem;
}


.ListPage .layout .search-container {
  position: absolute;
  z-index: 1;
  min-width: 0;
  left: 2rem;
  right: 1rem;
  padding: 2rem 2rem 0 2rem;
  width: auto;
  transition: all 300ms ease-in-out;
}

.layout .page-title-container h1 {
  font-size: 2.4rem;
}

.ListPage .layout .page-title-container {
  width: 0.1rem;
  white-space: nowrap;
  overflow: hidden;
}

.ListPage .layout .page-title-container h1 {
  padding: 0;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  margin-top:0.2rem;
  height: 5.5rem;
}

@media (min-width: 500px) {
  .ListPage .layout .search-container {
    position: absolute;
    z-index: 1;
    min-width: 350px;
    left: auto;
    right: 0;
    padding: 2rem 0 0 8rem;
  }

  .ListPage .layout .page-title-container {
    position: absolute;
    z-index: 1;
    width: auto;
    left: 2rem;
    right: auto;
    padding: 2rem 0 0;
  }
}

.ListPage .layout .search-container .search {
  width: 350px;
  border-radius: 2rem;
  font-size: 1.2rem;
  transition: all 200ms ease-in-out;
}

.ListPage .layout .search-container .search:focus {
  width: 100%;
  width: 400px;
  border-radius: 2rem;
  font-size: 1.2rem;
}

.ListPage .layout .list-container {
  position: absolute;
  z-index: 0;
  width: auto;
  left: 0;
  right: 0;
  padding: 6rem 0 0 0;
  top: 0;
  bottom: -2;
  overflow-y: scroll;
}

.DefaultTheme.ListPage .lander {
  padding: 2rem 0 2rem 2rem;
}

.DefaultTheme.ListPage .lander.align-top .lander-content {
  width: calc(100% - 1rem);
}

@media (min-width: 980px)
{
  .DefaultTheme.Register .lander.align-top .lander-content {
    width: 100%;
  }

  .DefaultTheme.Reports .lander.align-top .lander-content {
    width: 100%;
  }
}

.ListPage .lander .lander-content{
  max-width: none;
  min-width: 70%;
}

.ListPage .lander.maximize{
  flex: 1;
}

.ListPage .lander.align-top  {
  align-items: normal;
}

.ListPage .lander.align-top .lander-content  {
  width: 90%;
  align-items: initial;
}


.ListPage .lander.maximize h2{
  color: #8088f9;
  font-size: 6rem;
}

.ListPage .lander.maximize p{
  color: #999AA2;
  font-size: 1.2rem;
}


/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #e6e8ff;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #e6e8ff;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.ScrollView {
  overflow-y: scroll;
}

/*Hides the y overflow scrollbar*/
/* remove scrollbar space */
/* optional: just make scrollbar invisible */
/*
.ScrollView ::-webkit-scrollbar {
    width: 0px;  
    background: transparent;  
}
*/

.progress .progress-bar {
  -webkit-transition: none;
       -o-transition: none;
          transition: none;
}

