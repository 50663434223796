body {
  margin: 0;
  padding: 0;
  font-family: "Lucida Sans Unicode", Helvetica, sans-serif;
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: #222;
}

#root {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #FAFAF9;
}

.App {
  height: 100%;
  overflow: scroll;
}

.Page {
  top: 0;
  bottom: 0;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: none;
	background-color: transparent;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #FFF;
}

::-webkit-scrollbar-thumb
{
	background-color: #DDD;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
          animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
