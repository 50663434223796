.recharts-legend-wrapper {
	margin-top: -20px;
}

.recharts-legend-item-text {
	font-size: 12px;
	fill: '#333333';
	font-color: '#333333',
}

.recharts-label {
	font-size: 12px;
	fill: '#333333';
	font-color: '#333333',
}
