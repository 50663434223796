.DefaultTheme {
  background: #F3F4F6;
}

.DefaultTheme.Public {
  background: #FAFAF9;
  height: calc(100vh - 7.5rem);
  overflow-y: auto;
  width: 100vw;
  top: 5rem;
  position: absolute;
}

.DefaultTheme.Public .lander {
  width:100%;
}

.DefaultTheme.Public .lander h1 {
  color: #2b247e;
}

.DefaultTheme .shelf .btn.btn-danger, .DefaultTheme .shelf .btn.btn-info, .DefaultTheme .shelf .btn.btn-warning, .DefaultTheme .shelf .btn.btn-success, .DefaultTheme .shelf .btn.btn-primary {
  border-radius: 0.2rem;
  border: none;
}


.DefaultTheme .lander {
  background: #FAFAF9;
}

.DefaultTheme .lander.maximize.alert h2{
  color: #8088f9;
}

.DefaultTheme .lander.maximize.alert p{
  color: #999AA2;
}

.DefaultTheme.Error {
  background: #2b247e;
}

.DefaultTheme.Error .lander {
  background: transparent;
}

.DefaultTheme.Error h1 {
  color: #8088f9;
}

.DefaultTheme.Error p {
  color: #FFF;
}

.DefaultTheme .banner {
  color: #2b247e;
  background: #e6e8ff;
}

.DefaultTheme .list-container {
  background: #f0f0f0;
}

.DefaultTheme .list-item .list-item-button {
  background: #b9bdff;
  color: #FFF;
}

.DefaultTheme .list-item .list-item-content
{
  background: #FFF;
}

.DefaultTheme .list-item .list-item-content .col-1 h1{
  color: #2b247e;
}

.DefaultTheme .list-item .list-item-content .col-1 h1 .item-tags span {
  background: #453d9c;
  color: #e6e8ff;
}

.DefaultTheme .list-item .list-item-content .col-1 p{
  color: #2b247e;
}

.DefaultTheme .list-item .list-item-content .col-1 .label {
  color: #2b247e;
}

.DefaultTheme .list-item .list-item-content .col-2 {
  background: #FFF;
}

.DefaultTheme .list-item .list-item-content .col-3 {
  background: #FFF;
}

.DefaultTheme .shelf {
  background: #f8f8f8;
}

.DefaultTheme .shelf-nav-button {
  background: #8088f9;
  color: #FFF;
}

.DefaultTheme .shelf .shelf-nav-label {
  color: #8088f9;
  background: #e6e8ff;
}

.DefaultTheme .shelf .shelf-filter-box {
  background: #FFF;
  border-color: #EEEEEE;
}

.DefaultTheme .shelf .btn {
  background: #efefef;
  border-color: #dfdfdf;
  box-shadow: 0 0 0.75rem rgba(0,0,0,0.15);
  transition: all 200ms ease-in-out;
}

.DefaultTheme .shelf .btn:hover {
  background: #ffffff;
  border-color: #cfcfcf;
  box-shadow: 0 0 0.75rem rgba(0,0,0,0.1);
}

.DefaultTheme .shelf .btn.btn-danger {
  position: absolute;
  background: #f37575;
  border: 1px solid #f04545;
  box-shadow: 0 0 0.75rem rgba(0,0,0,0.35);
  transition: all 200ms ease-in-out;
  top: calc(100vh - 13rem);
  width: 210px;
}

.DefaultTheme .shelf .btn.btn-danger:hover {
  background: #e02424;
  border: 1px solid #e02424;
  box-shadow: 0 0 0.5rem rgba(0,0,0,0.5);
}

.DefaultTheme .shelf .shelf-filter-box-title {
  color: #464646;
  border-bottom-color: #e6e8ff;
}

.DefaultTheme .shelf .shelf-filter-box-title .nuonic-icon {
  color: #8088f9;
}

.DefaultTheme .shelf .shelf-filter-box-options .checkbox {
  color: #2b247e;
}

.DefaultTheme .shelf .shelf-filter-box-options {
  color: #B4B4B4;
}

.DefaultTheme .shelf .input-group-btn {
  border-top: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  border-left: 1px solid #EEE;
}

.DefaultTheme .shelf .input-group-addon {
  background: #FFF;


}

.DefaultTheme .shelf .btn.dropdown-toggle.btn-default {
    background: #fbfbfb;
}

.DefaultTheme .shelf .dropdown-toggle .caret {
  display: none;
}


.DefaultTheme .shelf .input-group-btn .btn {
  border: none;
  background: #FFF;
}


.DefaultTheme .category-tag {
  background: #8088f9;
  color: #FFF;
}

.DefaultTheme .row.spacer {
  background: #e6e6e6;
}

.DefaultTheme .row.subheading {
  color: #8088f9;
}


.DefaultTheme .has-success .checkbox,
.DefaultTheme .has-success .checkbox-inline,
.DefaultTheme .has-success .control-label,
.DefaultTheme .has-success .help-block,
.DefaultTheme .has-success .radio,
.DefaultTheme .has-success .radio-inline,
.DefaultTheme .has-success.checkbox label,
.DefaultTheme .has-success.checkbox-inline label,
.DefaultTheme .has-success.radio label,
.DefaultTheme .has-success.radio-inline label {
  color: #6e6e6e;
}

.DefaultTheme .has-success .form-control {
  border-color: #8088f9;
}

.DefaultTheme .has-success .form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #8088f9;
}

.DefaultTheme .has-success .form-control-feedback {
  color: #cdd1ff;
}

.DefaultTheme .ReportSummarySectionTitle {
  border-color: #7f88f9;
}

.DefaultTheme .ReportSummarySectionFooter {
  border-color: #7f88f9;
}


.DefaultTheme .ReportSummarySectionHeading {
  border-color: #7f88f9;
}

.DefaultTheme .rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
  background-color: #8088f9;
}
